import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import {Observable} from 'rxjs';
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import {Role} from '../models/role.model';
import {AbilityUpdateRequest} from '../models/ability-update-request.model';


@Injectable()
export class RoleService{

  private resourceUrl = '/api/admin/role'

  private roles: Array<Role> = [];

  constructor(private http: HttpClient) { }


  /**
   * Retrieve Customer from cache or fetch from server if not found
   * @param guid
   * @param force     Force fetch from server
   * @returns {any}
   */
  get(force?: boolean): Observable<Array<Role>> {

    //TODO: Expire cache

    // If force is true, remove roles from cache
    if (force === true || this.roles.length < 1) {
      this.roles = [];

    } else {

        // return Observable of existing roles
      return of(this.roles);    
      // .map(
      //     resp => resp as Array<Role>
      // );
    }

    return this.fetch();
  }


  /**
   * Fetch customer entity from the server
   * @param guid
   * @returns {any}
   */
  private fetch(): Observable<Array<Role>> {

    return this.http
        .get<Array<Role>>(this.resourceUrl)
        .pipe(
            tap(
                (roles: Array<Role>) => {
                    // Cache roles
                    this.roles = roles;
                }
            )
        );
  }


    /**
     * Save user roles (and update cached user)
     * @param roleId
     * @param roles
     * @returns {Observable<T>}
     */
    saveAbilities(roleId, abilities: AbilityUpdateRequest): Observable<Role> {
        const headers = new HttpHeaders()
            .set("Content-Type", "application/json");

        return this.http.put<Role>(this.resourceUrl + '/abilities/' + roleId, abilities, {headers})
            .pipe(
                tap( (role: Role) => {
                        //Update cached roles
                        this.get(true);
                    })
            );
    }
}
