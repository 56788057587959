import {ErrorHandler, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import {environment} from '../../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  /**
   * Whether initSentry() was run.  Should only happen on production.
   */
  private usingSentry = false;

  constructor() {
    if (environment.production) {
      this.initSentry();
    }
  }

  private sendToSentry(extractedError): void {
    // Capture handled exception and send it to Sentry.
    if (this.usingSentry) {
      Sentry.captureException(extractedError);
    }
  }

  /**
   * Note: running this on any environment will cause issues to be reported to Sentry, regardless
   * of you calling Sentry.captureException().  That library has hooks all over the place.
   */
  private initSentry(): void {
    Sentry.init({
      dsn: 'https://a70693aa16d44c62b41358aa67b73694@o401842.ingest.sentry.io/5280710',
      release: environment.release,
      // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
      // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
      // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
      // integrations: [ new Sentry.Integrations.TryCatch({
      //   XMLHttpRequest: false,
      // })],
    });

    this.usingSentry = true;
  }

  private extractError(error) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  handleError(error) {
    const extractedError = this.extractError(error) || 'Handled unknown error';
    // let eventId = '';

    this.sendToSentry(extractedError);

    // When in development mode, log the error to console for immediate feedback.
    // if (!environment.production) {
    console.error(extractedError);
    // }

    // Optionally show user dialog to provide details on what happened.
    // Sentry.showReportDialog({ eventId });
  }
}
