<div class="card rs-card rs-card-blue">
  <div class="card-body">

    <div class="alert alert-primary" role="alert" *ngIf="userId==1">
      <i class="fa fa-info-circle"></i> Admin is like God.  He can do everything.
    </div>

    <div class="row" *ngIf="abilities.length > 0">
      <div class="col col-12 col-sm-6" *ngFor="let ability of abilities">
        <div class="form-check disabled">

          <label class="form-check-label" *ngIf="userId!=1">
            <input class="form-check-input"
                   type="checkbox"
                   name="county"
                   [value]="ability.name"
                   [checked]="hasAbility(ability.name)"
                   (change)="onSelectionChange(ability.name)"
            > {{ability.name}}
          </label>


          <label class="form-check-label" *ngIf="userId==1">
            <span [ngSwitch]="hasAbility(ability.name)">
              <i class="fa fa-check-square-o" *ngSwitchCase="true"></i>
              <i class="fa fa-square-o" *ngSwitchDefault=""></i>
              {{ability.name}}
            </span>
          </label>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="d-flex flex-row justify-content-end">
        <div classs="p-2">
          <button type="button" class="btn btn-sm btn-primary pull-right" (click)="save()" *ngIf="isDirty()">Save</button>
        </div>
      </div>
    </div>

  </div>
</div>
