import {EventEmitter, Injectable, Output} from '@angular/core';
import {User} from '../../shared/models/user.model';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';
import {ComponentState} from '../models/component-state.model';
import {RoleUpdateRequest} from '../models/role-update-request.model';
import {AbilityUpdateRequest} from '../models/ability-update-request.model';
import {NewUserRequest} from '../models/new-user-request';
import {ChangePasswordRequest} from '../models/change-password-request';


@Injectable()
export class UserAclService {

    /**
     * URI for user
     */
    private resourceUrl = '/api/admin/user';

    /**
     * Cache lifetime
     */
    private cacheExpirationAge = 120;

    /**
     * The user entities
     */
    protected user: User;

    // /**
    //  * User cache stores last 10 users
    //  */
    // public users: Array<User> = [];

    /**
     * cache timestamp of last fetch
     */
    public userCacheTimestamps = {};


    protected selectedRoles = [];

    /**
     * Flag if user state requires saving
     */
    protected isDirty = {
        details: false,
        roles: false,
        abilities: false
    };

    /**
     * Observable for current fetch so we can return same observable when multiple requests fire
     */
    public userObservable: Observable<User>;

    /**
     * Event emitter for user updates
     */
    @Output() onUserStateChangeEvent = new EventEmitter<any>();

    /**
     * Event emitter for user updates
     */
    @Output() onCreateNewUserEvent = new EventEmitter<any>();


    constructor(private http: HttpClient) {}


    /**
     * Create new user
     */
    create(newUserRequest: NewUserRequest): Observable<Object> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');

        return this.http.post(this.resourceUrl, newUserRequest, {headers})
            .pipe(
                tap(
                    (user: User) => {
                        this.onCreateNewUserEvent.emit(user);
                    }
                )
            );
        // TODO: Updates need to update the cache
    }


    /**
     * Update User Record
     * @param guid
     * @param params
     * @returns {Observable<Object>}
     */
    update(guid, params) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');

        return this.http.put(this.resourceUrl + '/' + guid, params, {headers});
        // TODO: Updates need to update the cache
    }


    /**
     *
     * @param guid
     * @param {ChangePasswordRequest} changePasswrdRequest
     * @returns {Observable<User>}
     */
    changePassword(guid, changePasswordRequest: ChangePasswordRequest) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');

        return this.http.put(this.resourceUrl + '/' + guid + '/passwd', changePasswordRequest, {headers})
            .pipe(
                tap(
                    (user: User) => {
                        // this.onCreateNewUserEvent.emit(user);
                    }
                )
            );
    }


    /**
     * Search for all users matching the search criteria
     * @param searchCriteria
     * @param page
     * @returns {Observable<Object>}
     */
    search(searchCriteria, page) {
        let params = new HttpParams();
        params = params.append('page', page);

        Object.keys(searchCriteria).map(key => {
            if (searchCriteria[key]) {
                params = params.append(key, searchCriteria[key]);
            }
        });

        return this.http.get<LengthAwarePaginator>(this.resourceUrl + 's/search', {params: params});
    }

    /**
     * Retrieve Customer from cache or fetch from server if not found
     */
    get(userId, force?: boolean): Observable<User> {


        return this.fetch(userId);
    }


    /**
     * Fetch user entity from the server
     * @param guid
     * @returns {any}
     */
    private fetch(userId): Observable<User> {

        if (!this.userObservable ) {

            this.userObservable = this.http
                .get<User>(this.resourceUrl + '/' + userId)
                .pipe(
                    tap(
                        user => {
                            this.user = user;
                            this.userCacheTimestamps[user.id] = {};
                            this.userCacheTimestamps[user.id].timestamp = new Date();
                            this.userObservable = null;
                        }
                    )
                );
                // .share();
        }

        return this.userObservable;
    }

    /**
     *
     * @param userId
     * @returns {boolean}
     */
    isExpired(userId) {
        let isExpired = true;
        const currentDate = new Date();
        let diffSeconds = 0;

        if (this.userCacheTimestamps.hasOwnProperty(userId)) {
            const diff = currentDate.getTime() - this.userCacheTimestamps[userId].timestamp;
            diffSeconds = Math.floor(diff / 1000);

            if (diffSeconds > this.cacheExpirationAge) {
                isExpired = true;
            }
        }

        return isExpired;
    }

    /**
     * Return true if user has the named role
     */
    public hasRole(roleName: string) {
      return this.user.roles.indexOf(roleName) > -1;
    }

    /**
     * Return true if user has the named role
     */
    public hasAbility(abilityName: string) {
        let hasAbility = false;

        if (this.user) {
            this.user.abilities.map(ability => {
                if (ability.name === abilityName || ability.name === '*') {
                    hasAbility = true;
                    return;
                }
            });
        }

        return hasAbility;
    }

    /**
     * Notify any subscribers
     * @param event
     */
    setDirtyState(event: ComponentState) {
        if (this.isDirty.hasOwnProperty(event.name)) {
            this.isDirty[event.name] = event.isDirty;
        }
    }

    /**
     * Save user roles (and update cached user)
     * @param userId
     * @param roles
     * @returns {Observable<T>}
     */
    saveRoles(userId, roles: RoleUpdateRequest): Observable<User> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');

        return this.http.put<User>(this.resourceUrl + '/roles/' + userId, roles, {headers})
            .pipe(
                tap(
                    (user: User) => {
                        this.user = user;
                        // this.isFetching = false;
                        this.userCacheTimestamps[user.id] = {};
                        this.userCacheTimestamps[user.id].timestamp = new Date();
                        this.notify('roles');
                    }
                )
            );
    }

    /**
     * Save user roles (and update cached user)
     * @param userId
     * @param roles
     * @returns {Observable<T>}
     */
    saveAbilities(userId, abilities: AbilityUpdateRequest): Observable<User> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');

        return this.http.put<User>(this.resourceUrl + '/abilities/' + userId, abilities, {headers})
            .pipe(
                tap(
                    (user: User) => {
                        this.user = user;
                        // this.isFetching = false;
                        this.userCacheTimestamps[user.id] = {};
                        this.userCacheTimestamps[user.id].timestamp = new Date();
                        this.notify('abilities');
                    }
                )
            );
    }

    /**
     * Emit user changed event
     * @param event
     */
    notify(event) {
        this.onUserStateChangeEvent.emit(event);
    }
}
