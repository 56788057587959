<div class="container">

    <h3>Role ACL</h3>
    <p>Select a role to edit the abilities for that role.</p>

    <div class="row" *ngIf="roles.length > 0">
        <div class="col col-12">
            <ul class="nav nav-tabs">
                <li class="nav-item"
                    *ngFor="let role of roles"
                    (click)="selectRole(role.name)">
                    <a class="nav-link clickable" [ngClass]="{'active': selectedRole==role.name}">{{role.name}}</a>
                </li>
            </ul>
        </div>
    </div>


    <div  *ngIf="roles.length > 0">
        <div *ngFor="let role of roles" >
            <app-acl-selector [role]="role" [isVisible]="selectedRole==role.name"></app-acl-selector>
        </div>
    </div>


    <app-add-ability></app-add-ability>
</div>