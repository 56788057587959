import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Ability} from '../models/ability.model';


@Injectable()
export class AbilityService {

    /**
     * URI
     * @type {string}
     */
    private resourceUrl = '/api/admin/ability'

    /**
     * Cache lifetime
     * @type {number}
     */
    private cacheExpirationAge = 60;

    /**
     *
     * @type {Array}
     */
    private abilities: Array<Ability> = [];

    /**
     * Track if we are fetching already to prevent repeat calls to backend
     * @type {boolean}
     */
    protected isFetching = false;

    /**
     * Cache timestamp
     * @type {any}
     */
    protected timestamp = null;

    /**
     * Observable for current fetch so we can return same observable when multiple requests fire
     */
    public abilityObservable : Observable<Array<Ability>>;

    /**
     * Event emitter for user updates
     * @type {EventEmitter<any>}
     */
    @Output() onUpdateAbilities = new EventEmitter<any>();


    constructor(private http: HttpClient) {
    }


    /**
     * Retrieve Customer from cache or fetch from server if not found
     * @param guid
     * @param force     Force fetch from server
     * @returns {any}
     */
    get(force?: boolean): Observable<Array<Ability>> {
        // If force is true, clear cache, else return cached abilities
        if (force === true || this.isExpired() || this.abilities.length === 0) {
            return this.fetch();
        }

        // Return the cached abilities
        return of(this.abilities)
            //.share()
            .pipe(map(
                resp => resp as Array<Ability>
            ));
    }


    /**
     * Fetch customer entity from the server
     * @param guid
     * @returns {any}
     */
    private fetch(): Observable<Array<Ability>> {

        if (!this.abilityObservable ) {

            this.abilityObservable = this.http
                .get<Array<Ability>>(this.resourceUrl)
                .pipe(
                    tap(
                        (abilities: Array<Ability>) => {
                            // Cache abilities
                            this.abilities = abilities;
                            this.abilityObservable = null;
                            this.timestamp = new Date();
                        }
                    )
                );
                //.share();
        }

        return this.abilityObservable;
    }


    /**
     *
     * @param userId
     * @returns {boolean}
     */
    isExpired() {
        let isExpired = false;
        const currentDate = new Date();
        let diffSeconds = 0;

        if(this.timestamp) {
            const diff = currentDate.getTime() - this.timestamp;
            diffSeconds = Math.floor(diff/1000);

            if(diffSeconds > this.cacheExpirationAge){
                isExpired = true;
            }
        }

        return isExpired;
    }


    add(ability) {
        return this.http.put(this.resourceUrl + '/' + ability, {})
            .pipe(
                tap((ability: Ability) => {
                    this.abilities.push(ability);
                    this.notify(ability);
                })
            );
    }


    notify(ability) {
        this.onUpdateAbilities.emit(ability);
    }
}
