import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {UserAclService} from '../../services/user-acl.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ErrorService} from '../../../shared/error/error.service';
import {AlertService} from "@ratespecial/core";

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.css']
})
export class ChangePasswordModalComponent implements OnInit {

    /**
     * Current user id
     * @type {number}
     */
    public user: any;

    /**
     * Form Model
     */
    public changePasswordRequest = {
        password: '',
        password_confirmation: ''
    };

  constructor(
      public activeModal: NgbActiveModal,
      public userAclService: UserAclService,
      public errorService: ErrorService,
      public alertService: AlertService) { }

  ngOnInit() {
  }

  save() {

    this.userAclService
            .changePassword(this.user.id, this.changePasswordRequest)
            .subscribe(
                (data) => {
                    this.alertService.success("Password Changed");
                    //this.noteService.notify(true);
                    this.close();
                },
                (err: HttpErrorResponse) => {
                    this.errorService.handleValidationErrors(err);
                }
            );
  }

  close() {
        this.activeModal.close();
  }
}
