<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Add New User</h5>
  <button type="button" class="close clickable" data-dismiss="modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <!-- Errors -->
  <app-error></app-error>

  <form>
    <!-- Name -->
    <div class="mb-3 row">
      <label for="name" class="col-sm-4 col-form-label">Name</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="name"
               name="name"
               [(ngModel)]="newUserRequest.name"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['name']}">
      </div>
    </div>

    <!-- Username -->
    <div class="mb-3 row">
      <label for="username" class="col-sm-4 col-form-label">Username</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="username"
               name="username"
               required
               [(ngModel)]="newUserRequest.username"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['username']}">
      </div>
    </div>

    <!-- Email -->
    <div class="mb-3 row">
      <label for="email" class="col-sm-4 col-form-label">Email</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="email"
               name="email"
               required
               [(ngModel)]="newUserRequest.email"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['email']}">
      </div>
    </div>

  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()">Save</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>





