<form class="form-inline" name="ability-form">
  <label class="sr-only" for="ability">Ability</label>
  <input
      type="text"
      class="form-control mb-2 mr-sm-2"
      id="ability"
      name="ability"
      placeholder="Add Ability"
      [(ngModel)]="ability">
  <button type="submit" class="btn btn-primary mb-2" (click)="addAbility()">Submit</button>
</form>