import {Component, Inject} from '@angular/core';
import {APP_CONFIG} from './app.config';
import {IAppConfig} from './app-config.interface';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  title = '';

  constructor(
    @Inject(APP_CONFIG) public config: IAppConfig,
    private titleService: Title,
  ) {
    this.title = config.appName;
  }

  ngOnInit() {
    this.titleService.setTitle(this.config.appName);
  }
}
