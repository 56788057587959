import {Component, OnInit} from '@angular/core';
import {AbilityService} from '../../services/ability.service';
import {UserAclService} from '../../services/user-acl.service';
import {Ability} from '../../models/ability.model';
import {User} from '../../../shared/models/user.model';
import {ActivatedRoute} from '@angular/router';
import {AbilityUpdateRequest} from '../../models/ability-update-request.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from '../../../shared/error/error.service';
import {AlertService} from "@ratespecial/core";

@Component({
  selector: 'app-ability-selector',
  templateUrl: './ability-selector.component.html',
  styleUrls: ['./ability-selector.component.css']
})
export class AbilitySelectorComponent implements OnInit {

  /**
   * Current user id
   * @type {number}
   */
  public userId: number = 0;

  /**
   * Available Abilities
   * @type {any[]}
   */
  public abilities: Array<Ability> = [];

  /**
   * Abilities Form
   * @type {any[]}
   */
  public selectedAbilities = [];

  /**
   * User's current abilities
   * @type {Array}
   */
  public userAbilities = [];


  constructor(
      private route: ActivatedRoute,
      private abilityService: AbilityService,
      private userAclService: UserAclService,
      private alertService: AlertService,
      private errorService: ErrorService
  ) { }


  ngOnInit() {
    this.userId = this.route.snapshot.params['id'];
    this.getAllAbilities();
    this.loadUserAbilities();

    //Subscribe to user update events
    this.userAclService.onUserStateChangeEvent
        .subscribe((event) => {
          // role changes can effect abilities too.
          if (event === 'roles' || event === 'abilities') {
            this.loadUserAbilities()
          }
        });
  }


  /**
   * Get list of all abilities for checkbox options
   */
  getAllAbilities() {
    this.abilityService.get().subscribe(data => {
      this.abilities = data;
    });
  }


  /**
   * Load user's current abilities
   */
  loadUserAbilities() {
    this.userAclService.get(this.userId).subscribe(
        (user: User) => {
          this.selectedAbilities = [];
          this.userAbilities = [];
          user.abilities.map( ability => {
            this.selectedAbilities.push(ability.name);
            this.userAbilities.push(ability.name);
          });
        }
    );
  }


  /**
   * Return true if ability is selected
   * @param name
   * @returns {boolean}
   */
  hasAbility(name) {
    return (this.selectedAbilities.indexOf(name) > -1) ? true : false;
  }


  /**
   * Handler for checkbox changes
   * @param name
   */
  onSelectionChange(name) {
    const index = this.selectedAbilities.indexOf(name);

    if(index > -1) {
      this.selectedAbilities.splice(index, 1);
    } else {
      this.selectedAbilities.push(name);
    }
  }


  /**
   * Check if changes were made by comparing selected abilities to user's current abilities
   * @returns {boolean}
   */
  isDirty() {
    let isDirty = false;

    if(this.selectedAbilities.length === this.userAbilities.length) {
      this.selectedAbilities.sort();
      this.userAbilities.sort();

      for (var i = 0; i < this.selectedAbilities.length; i++) {
        if(this.selectedAbilities[i] !== this.userAbilities[i]){
          isDirty = true;
          break;
        }
      }
    }else{
      isDirty = true;
    }

    this.userAclService.setDirtyState({name: 'roles', isDirty: isDirty});
    return isDirty;
  }


  /**
   * Save user role changes
   */
  save() {
    let abilityUpdateRequest: AbilityUpdateRequest = {
      abilities: this.selectedAbilities
    }

    this.userAclService.saveAbilities(this.userId, abilityUpdateRequest)
        .subscribe(
            (user: User) => {
              this.alertService.success('Abilities have been updated');
              this.loadUserAbilities();
            },
            (err: HttpErrorResponse) => {
                this.errorService.handleValidationErrors(err);
            });
  }
}
