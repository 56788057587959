<router-outlet name="header"></router-outlet>

<rs-alerts></rs-alerts>
<router-outlet></router-outlet>

<router-outlet name="footer"></router-outlet>

<rs-load-indicator [iconStyle]="'cube'"></rs-load-indicator>

<!--<hotkeys-cheatsheet></hotkeys-cheatsheet>-->
