import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserAclService} from '../../services/user-acl.service';
import {LengthAwarePaginator} from '../../../shared/models/LengthAwarePaginator';
import {CreateUserModalComponent} from '../create-user-modal/create-user-modal.component';
import {User} from '../../../shared/models/user.model';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

    public userPage: LengthAwarePaginator;

    public searchModel = {
        // name: '',
        // username: '',
        // email: '',
        searchText: '',
    }

    constructor(
        private userAclService: UserAclService,
        private modalService: NgbModal,
    ) {}

    ngOnInit() {
        this.userAclService
            .onCreateNewUserEvent
            .subscribe(
                (user: User) => {
                    this.getUsers(1);
                }
            );

        this.getUsers(1);
    }

    onPageChange(page) {
        this.getUsers(page);
    }

    getUsers(page) {
        this.userAclService.search(this.searchModel, page)
            .subscribe((lengthAwarePaginator : LengthAwarePaginator) => {
            this.userPage = lengthAwarePaginator;
        });
    }

    filter(filterBy: String) {
        this.getUsers(1);
    }

    search() {
        this.getUsers(1);
    }

    reset() {
        // this.searchModel.name = '';
        // this.searchModel.username = '';
        // this.searchModel.email = '';
        this.searchModel.searchText = '';
        this.getUsers(1);
    }

    /**
     * Event handler for refetching Notes from server
     */
    refresh(event) {
        this.getUsers(1);
    }

    /**
     * Show Edit Customer Modal
     */
    addUser() {
        const modalRef = this.modalService.open(CreateUserModalComponent);
    }
}
