import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';

@Injectable()
export class ActionLogService {

  private resourceUrl = 'api/admin/log/action';


  constructor(private http: HttpClient) { }

  /**
   * Retrieve a specific note by its ID
   * @param id
   * @returns {Observable<Object>}
   */
  get(actionLogId) {
    return this.http.get(this.resourceUrl + '/' + actionLogId);
  }


  search(searchCriteria, page) {
    let params = new HttpParams();
    params = params.append('page', page);

    Object.keys(searchCriteria).map(key => {
      if(searchCriteria[key]) {
        params = params.append(key, searchCriteria[key]);
      }
    });

    return this.http.get<LengthAwarePaginator>(this.resourceUrl + 's', {params: params});
  }
}
