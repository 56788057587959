<div class="container">

  <!-- Errors -->
  <app-error></app-error>


  <div class="d-flex justify-content-between">

    <h2 class="p-2">{{user?.name}}</h2>

    <!-- Tool Bar -->
    <div class="p-10">
      <div class="d-flex flex-row">
        <!--<div class="p-2">-->
          <!--<i (click)="editUser()" class="fa fa-lg fa-pencil clickable" aria-hidden="true" ngbTooltip="Edit User"></i>-->
        <!--</div>-->
        <div class="p-2">
          <i (click)="changePassword()" class="fa fa-lg fa-key clickable" aria-hidden="true" ngbTooltip="Change Password"></i>
        </div>
        <!--<div class="p-2">-->
          <!--<i (click)="cancelAccount()" class="fa fa-lg fa-ban clickable" aria-hidden="true" ngbTooltip="Disable Account"></i>-->
        <!--</div>-->
      </div>
    </div>
  </div>


  <h3>{{user?.name}}</h3>

  <dl class="row">
    <dt class="col-3">Username</dt>
    <dd class="col-7">{{user?.username}}</dd>

    <dt class="col-3">Email</dt>
    <dd class="col-7">{{user?.email}}</dd>
  </dl>

  <div  class="padding-top-35">
    <h4>Roles</h4>
    <app-role-selector></app-role-selector>
  </div>

  <div  class="padding-top-35">
    <h4>Abilities</h4>
    <p>Try to assign abilities to roles and roles to user, before adding abilities to individual users.</p>
    <app-ability-selector></app-ability-selector>
  </div>

</div>