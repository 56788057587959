import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserAclService} from '../../services/user-acl.service';
import {ErrorService} from '../../../shared/error/error.service';
import {NewUserRequest} from '../../models/new-user-request';
import {AlertService} from "@ratespecial/core";

@Component({
    selector: 'app-create-user-modal',
    templateUrl: './create-user-modal.component.html',
    styleUrls: ['./create-user-modal.component.css']
})
export class CreateUserModalComponent implements OnInit {

    /**
     * Form Model
     */
    public newUserRequest: NewUserRequest = {
        name: '',
        username: '',
        email: ''
    };

    constructor(
        public activeModal: NgbActiveModal,
        public userAclService: UserAclService,
        public errorService: ErrorService,
        public alertService: AlertService
    ) {}

    ngOnInit() {
    }

    save() {

        this.userAclService
            .create(this.newUserRequest)
            .subscribe(
                (data) => {
                    this.alertService.success("New user created");
                    //this.noteService.notify(true);
                    this.close();
                },
                (err: HttpErrorResponse) => {
                    this.errorService.handleValidationErrors(err);
                }
            );
    }


    close() {
        this.activeModal.close();
    }
}
