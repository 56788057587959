<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Change Password for {{user.name}}</h5>
  <button type="button" class="close clickable" data-dismiss="modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <!-- Errors -->
  <app-error></app-error>

  <form>

    <div class="mb-3">
      <label for="password">Password</label>
      <input type="password"
             class="form-control"
             id="password"
             name="password"
             [(ngModel)]="changePasswordRequest.password"
             [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['password']}">
    </div>

    <div class="mb-3">
      <label for="password_confirmation">Confirm Password</label>
      <input type="password" class="form-control"
             id="password_confirmation"
             name="password_confirmation"
             [(ngModel)]="changePasswordRequest.password_confirmation"
             [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['password_confirmation']}">
    </div>

  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()">Save</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Cancel</button>
</div>
