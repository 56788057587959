import { Component, OnInit } from '@angular/core';
import {AbilityService} from '../../services/ability.service';
import {Ability} from '../../models/ability.model';
import {AlertService} from "@ratespecial/core";

@Component({
  selector: 'app-add-ability',
  templateUrl: './add-ability.component.html',
  styleUrls: ['./add-ability.component.css']
})
export class AddAbilityComponent implements OnInit {

  public ability = '';

  constructor(
      private alertService: AlertService,
      private abilityService: AbilityService
  ) { }

  ngOnInit() {
  }

  addAbility() {
    this.abilityService.add(this.ability).subscribe((ability: Ability) => {
      this.ability = '';
      this.alertService.success('Ability has been added');
    });
  }
}
