<div class="container" >
  <!--<hr class="separator">-->
  <div class="d-flex justify-content-between">
    <h3 class="p-2">Users</h3>

    <!-- Toolbar -->
    <div class="p-10">
      <div class="d-flex flex-row page-actions">
        <div class="p-2">
          <div class="pa-icon cursor-pointer" (click)="addUser()" ngbTooltip="Add New User">
            <i class="fa fa-2x fa-user font-blue"></i>
            <i class="fa fa-lg fa-plus-circle"></i>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="card card-body rs-card rs-card-dark">
    <div class="row">
      <div class="col-sm-12">

        <div class="clearfix">
          <p class="float-left">{{userPage?.total}} Users</p>
          <div class="float-right">

            <form class="form-inline">
              <!--<div class="form-check form-check-inline">-->
                <!--<label class="form-check-label">-->
                  <!--<input class="form-check-input"-->
                         <!--type="radio"-->
                         <!--name="filterby"-->
                         <!--id="filterby1"-->
                         <!--value=""-->
                         <!--checked-->
                         <!--(change)="filter('all')"-->
                         <!--[(ngModel)]="searchModel.is_contacted">-->
                  <!--All-->
                <!--</label>-->
              <!--</div>-->
              <!--<div class="form-check form-check-inline">-->
                <!--<label class="form-check-label">-->
                  <!--<input class="form-check-input"-->
                         <!--type="radio"-->
                         <!--name="filterby"-->
                         <!--id="filterby2"-->
                         <!--value="0"-->
                         <!--(change)="filter('uncontacted')"-->
                         <!--[(ngModel)]="searchModel.is_contacted">-->
                  <!--Uncontacted-->
                <!--</label>-->
              <!--</div>-->
              <div class="input-group mb-2 mr-sm-2 mb-sm-0">
                <input type="text"
                       class="form-control"
                       id="searchtext"
                       name="searchText"
                       placeholder="Search Text"
                       [(ngModel)]="searchModel.searchText">
                <span class="input-group-btn">
                  <button type="submit" class="btn btn-primary" (click)="search()"><i class="fa fa-search"></i></button>
                </span>
              </div>
              <button type="button" class="btn btn-secondary" (click)="reset()">Reset</button>
            </form>
          </div>

        </div>

        <table class="table table-sm  table-search-results" *ngIf="userPage?.data.length > 0">
          <thead class="">
          <tr>
            <th class="text-center">ID</th>
            <th>Name</th>
            <th>Username</th>
            <th>Roles</th>
            <th>Last Login</th>
            <th>Email</th>
            <th class="text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of userPage?.data">
            <td>{{ user.id}}</td>
            <td>{{ user.name}}</td>
            <td>{{ user.username}}</td>
            <td><span *ngFor="let role of user.roles">{{role.name}}<br></span></td>
            <td>{{ user.last_login}}</td>
            <td>{{ user.email}}</td>
            <td class="text-center"><i class="fa fa-eye clickable" [routerLink]="['/admin/user/', user.id]"></i>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="pagination-footer margin-top-25">
          <div class="clearfix">
            <p class="float-right">Page: {{userPage?.current_page}}</p>
            <ngb-pagination *ngIf="userPage"
                            (pageChange)="onPageChange($event)"
                            class="float-left"
                            [collectionSize]="userPage?.total"
                            [pageSize]="userPage?.per_page"
                            [(page)]="userPage.current_page"
                            [maxSize]="5"
                            [rotate]="true"
                            [ellipses]="false"
                            [boundaryLinks]="true" size="sm"></ngb-pagination>
          </div>
        </div>

        <!--<pre>{{userPage | json}}</pre>-->

      </div>
    </div>
  </div>
</div>