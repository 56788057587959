import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {AclComponent} from './acl/acl.component';
import {UsersComponent} from './users/users.component';
import {AdminComponent} from './admin.component';
import {UserDetailComponent} from './user/user-detail/user-detail.component';
import {UserListComponent} from './user/user-list/user-list.component';
import {SharedModule} from '../shared/shared.module';
import {UserAclService} from './services/user-acl.service';
import {RoleService} from './services/role.service';
import {AbilityService} from './services/ability.service';
import {RoleSelectorComponent} from './user/role-selector/role-selector.component';
import {AbilitySelectorComponent} from './user/ability-selector/ability-selector.component';
import {AclSelectorComponent} from './acl/acl-selector/acl-selector.component';
import {ActionLogService} from './services/action-log.service';
import {APP_CONFIG, AppConfig} from '../app.config';
import {CreateUserModalComponent} from './user/create-user-modal/create-user-modal.component';
import {AddAbilityComponent} from './acl/add-ability/add-ability.component';
import {ChangePasswordModalComponent} from './user/change-password-modal/change-password-modal.component';
import {ErrorComponent} from '../shared/error/error.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule.forRoot(),
    FormsModule,
    ErrorComponent,
  ],
  providers: [
    {provide: APP_CONFIG, useValue: AppConfig},
    UserAclService,
    RoleService,
    AbilityService,
    ActionLogService,
  ],
  declarations: [
    AclComponent,
    UsersComponent,
        AdminComponent,
        UserDetailComponent,
        UserListComponent,
        RoleSelectorComponent,
        AbilitySelectorComponent,
        AclSelectorComponent,
        CreateUserModalComponent,
        AddAbilityComponent,
        ChangePasswordModalComponent
    ],
    exports: [RouterModule]
})
export class AdminModule { }
