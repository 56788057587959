<div *ngIf="isVisible">
  <div class="card rs-card rs-card-blue">
    <div class="card-body">
      <h4 class="">Abilities for {{role.name}}</h4>

      <div class="margin-top-15" *ngIf="role.name=='Admin'" >
          <div class="alert alert-primary" role="alert">
            <i class="fa fa-info-circle"></i> Admin is like God.  He can do everything.
          </div>

          <div class="row" *ngIf="abilities.length > 0">
            <div class="col col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let ability of abilities">
                <span [ngSwitch]="hasAbility(ability.name)">
                  <i class="fa fa-check-square-o" *ngSwitchCase="true"></i>
                  <i class="fa fa-square-o" *ngSwitchDefault=""></i>
                  {{ability.name}}
                </span>
            </div>
          </div>
      </div>

    <div *ngIf="role.name!='Admin'" class="margin-top-25">
        <div class="row" *ngIf="abilities.length > 0">
            <div class="col col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let ability of abilities">

                <div class="form-check disabled">
                    <label class="form-check-label">
                        <input class="form-check-input"
                               type="checkbox"
                               name="county"
                               [value]="ability.name"
                               [checked]="hasAbility(ability.name)"
                               (change)="onSelectionChange(ability.name)"
                        > {{ability.name}}
                    </label>
                </div>
            </div>
        </div>

        <div class="container" *ngIf="isDirty()">
            <div class="d-flex flex-row justify-content-end">
                <div classs="p-2">
                    <button type="button" class="btn btn-sm btn-outline-primary pull-right" (click)="reset()">Reset</button>
                    <button type="button" class="btn btn-sm btn-margin btn-primary pull-right" (click)="save()">Save</button>
                </div>
            </div>
        </div>
    </div>

    </div>
  </div>
</div>
