import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NavbarComponent} from './layout/navbar/navbar.component';
import {FooterComponent} from './layout/footer/footer.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {AuthGuard} from './shared/auth/services/auth.guard';
import {AdminComponent} from './admin/admin.component';
import {AclComponent} from './admin/acl/acl.component';
import {UserDetailComponent} from './admin/user/user-detail/user-detail.component';
import {UserListComponent} from './admin/user/user-list/user-list.component';
import {UserResolver} from './shared/auth/resolvers/user.resolver';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'auth',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    pathMatch: 'prefix',
    canActivate: [AuthGuard],
    resolve: {
      user: UserResolver,
    },
    children: [
      {path: '', pathMatch: 'prefix', component: NavbarComponent, outlet: 'header'},
      {path: '', pathMatch: 'prefix', component: FooterComponent, outlet: 'footer'},
      {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },

      {
        path: 'round-robin',
        loadChildren: () =>
          import('./modules/round-robin-billing/round-robin-billing.module').then(m => m.RoundRobinBillingModule)
      },

      {
        path: 'billing-cascade',
        pathMatch: 'prefix',
        loadChildren: () =>
          import('./modules/billing-cascade/billing-cascade.module').then(m => m.BillingCascadeModule)
      },

      {
        path: 'processor',
        pathMatch: 'prefix',
        loadChildren: () =>
          import('./modules/processor/processor.module').then(m => m.ProcessorModule)
      },
      {
        path: 'account-updater',
        pathMatch: 'prefix',
        loadChildren: () =>
          import('./modules/account-updater/account-updater.module').then(m => m.AccountUpdaterModule)
      },

      {
        path: 'campaigns',
        pathMatch: 'prefix',
        loadChildren: () =>
          import('./modules/campaigns/campaigns.module').then(m => m.CampaignsModule)
      },

      {
        path: 'rdr-import',
        pathMatch: 'prefix',
        loadChildren: () =>
          import('./modules/rdr-import/rdr-import.module').then(m => m.RdrImportModule)
      },

      {
        path: 'admin',
        component: AdminComponent,
        children: [
          {path: 'acl', component: AclComponent},
          {path: 'user/:id', component: UserDetailComponent},
          {path: 'users', component: UserListComponent}
        ]
      },
      {path: '**', component: PageNotFoundComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
