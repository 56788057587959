import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {User} from '../../../shared/models/user.model';
import {UserAclService} from '../../services/user-acl.service';
import {RoleService} from '../../services/role.service';
import {AbilityService} from '../../services/ability.service';
import {ChangePasswordModalComponent} from '../change-password-modal/change-password-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {

  public user: User;

  constructor(
      private route: ActivatedRoute,
      private userAclService: UserAclService,
      private roleService: RoleService,
      private abilityService: AbilityService,
      //private modalService: BsModalService,
      private modalService: NgbModal,
  ) { }


  ngOnInit() {
    this.getUser();
  }


  getUser() {
      let id = this.route.snapshot.params['id'];

      this.userAclService.get(id).subscribe(
          (user: User) => this.user = user
      );
  }

    /**
     * Show Edit Change Password Modal
     */
    changePassword() {
        const modalRef = this.modalService.open(ChangePasswordModalComponent);
        modalRef.componentInstance.user = this.user;
    }

    /**
     * Show Edit userr Modal
     */
    editUser() {
        const modalRef = this.modalService.open(ChangePasswordModalComponent);
        modalRef.componentInstance.user = this.user;
    }

    /**
     * Show Disable User Modal
     */
    disableUser() {
        const modalRef = this.modalService.open(ChangePasswordModalComponent);
        modalRef.componentInstance.user = this.user;
    }
}
